		
	// Styles Communs

		html {
			display: flex;
			flex-direction: column;
		}

		body {
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			font: 300 2rem $font-stack-common;
			color: $grey;
			background-color: $beige;

			@media (max-width: $tiny) {
				font-size: 1.8rem;
			}

			&.is-mobile-nav-open {
				overflow: hidden;
			}

			&.is-fixed {
				width: 100%;
				height: 100%;
				position: fixed;
				overflow: hidden;
			}
		}

		main {
			flex: 1 1 auto;
		}

		h1, h2 {
			&.title-home {
				margin-bottom: 3rem;
				font: 300 7rem $font-stack-common;
				letter-spacing: .2rem;
				text-transform: uppercase;
				text-align: center;
				color: $black;

				@media (max-width: $medium) {
					font-size: 5rem;
				}

				span {
					position: relative;
					top: -3rem;
					padding: 2rem;
					background-color: $beige;

					@media (max-width: $extra-large) {
						top: -4rem;
					}
				}
			}
		}

		h1.title-home {
			@media (max-width: $tiny) {
				margin-bottom: 1rem;
			}
		}

		h2.title-home {
			@media (max-width: $extra-large) {
				margin-bottom: 0;
			}
		}

		a {
			text-decoration: none;
			transition: color .3s ease-in-out;

			&:hover {
				color: $purple;
			}
		}

		.container-wrapper {
			position: relative;
		}

        .container {
        	position: relative;
            margin: 0 auto;
            padding: 0 2rem;
            max-width: 114rem;

            @media (max-width: $large) {
            	max-width: 94rem;
            }

            @media (max-width: $medium) {
            	max-width: 72rem;
            }

            @media (max-width: $small) {
            	max-width: 58rem;
            	padding: 0 1.5rem;
            }
        }

        ///// Correction frameborder (obsolète)

		iframe {
			border: none;
		}

		///// Iframe responsive fullscreen

		.iframe-wrapper {
			position: relative;
		}

		.iframe-sub-wrapper {
			position: relative;
			height: 0;
			padding: 0 0 56.25%;

		    iframe, embed, object {
				position: absolute;
			    top: 0;
			    left: 0;
			    width: 100% !important;
			    height: 100% !important;
			}
		}

		///// Style décos svg

        .svg-wave, .svg-curve {
        	position: absolute;
        	width: 100%;
        	fill: $beige;
        }

        .svg-curve {
			top: -4rem;

			@media (max-width: $medium) {
				top: -2rem;
			}
        }

        //// Image fixed

        .fixed-pic {
        	width: 100%;
        	height: 60rem;
        	background: {
        		position: center center;
        		repeat: no-repeat;
        		size: cover;
        	}

        	@media (min-width: $ipad + 1) {
        		background-attachment: fixed;
        	}

        	@media (max-width: $extra-large) {
        		height: 45rem;
        	}

        	@media (max-width: $medium) {
        		height: 35rem;
        	}

        	@media (max-width: $tiny) {
        		height: 25rem;
        	}
        }

        //// Style spécifique footer

        footer:not(.footer-accueil) {
        	background-color: $white;

        	.wave-wrapper svg {
        		fill: $white;
        	}
        }

        ///// Style mentiosn légales

        .mentions-legales {
        	padding: 15rem 0;

        	@media (max-width: $small) {
            	padding: 15rem 0 10rem;
            }

        	h2 {
        		font: 400 3rem $font-stack-common;
        		color: $purple-fade;
        	}
        }

        .error-404 {
        	min-height: calc(100vh - 11rem);
        	padding: 15rem 0;
        	color: $white;
        	background: linear-gradient(to bottom, $purple-fade, rgba($purple, .5));

        	@media (max-width: $small) {
            	padding: 15rem 0 10rem;
            }

            .title-home {
            	color: $white;
            }
        }