// Style page d'accueil

	.home-intro {
		@media (max-width: $medium) {
			padding-top: 11rem;
			transition: padding-top .3s ease-in-out;

			&.is-scroll {
				padding-top: 9rem;
			}
		}

		.svg-wave {
			top: -7rem;

			@media (max-width: $tiny) {
				top: -3rem;
			}
		}

		.container {
			padding: 7rem 2rem 14rem;

			@media (max-width: $extra-large) {
				padding: 1rem 2rem 7rem;
			}

			@media (max-width: $small) {
				padding: 1rem 1.5rem 7rem;
			}

			@media (max-width: $tiny) {
				padding: 1rem 1.5rem 4rem;
			}
		}
	}

	.home-products {
		overflow: hidden;

		h2.title-home span {
			top: -7rem;

			@media (max-width: $extra-large) {
				top: -4rem;
			}
		}

		.container {
			padding: 4rem 2rem 14rem;

			@media (max-width: $extra-large) {
				padding: 0 2rem 7rem;

				p:first-of-type {
					margin: 0;
				}
			}

			@media (max-width: $small) {
				padding: 0 1.5rem 4rem
			}
		}

		.brands-wrapper {
			margin: 2rem -2rem 0;

			@media (max-width: $large) {
				margin-bottom: -2rem;
			}

			@media (max-width: $tiny) {
				margin: 2rem 0 -2rem;
			}
		}

		.brand {
			width: calc(25% - 4rem);
			margin: 0 2rem;

			@media (max-width: $large) {
				width: calc(50% - 4rem);
				margin-bottom: 2rem;
			}

			@media (max-width: $tiny) {
				width: 100%;
				margin: 0 0 2rem;
			}

			img {
				width: 100%;
				height: auto;
			}

			h3 {
				margin: 1.5rem 0 0;
				font-weight: 700;
				text-transform: uppercase;
				font-size: 1.6rem;
			}

			p {
				margin-top: .5rem;
				font-size: 1.6rem;
			}
		}
	}

	.home-galeries {
		@media (max-width: $extra-large) {
			padding-bottom: 4rem;
		}

		@media (max-width: $small) {
			padding-bottom: 3rem;
		}
		
		h3 {
			margin: 0 0 1.5rem;
			text-align: center;
			font-weight: 400;
			font-size: 2.7rem;

			@media (max-width: $small) {
				margin-bottom: 1rem;
				font-size: 2.3rem;
			}
		}

		.carrousel-slider {
			padding-bottom: 10rem;

			@media (max-width: $extra-large) {
				padding-bottom: 4rem;
			}

			@media (max-width: $small) {
				padding-bottom: 2rem;
			}
		}

		.ms-slide {
			@extend %bg-cover;
		}
	}

	.home-prices {		
		.container {
			padding-bottom: 14rem;

			@media (max-width: $extra-large) {
				padding-bottom: 7rem;
			}

			@media (max-width: $medium) {
				padding: 0 0 7rem
			}

			@media (max-width: $tiny) {
				padding-bottom: 4rem;
			}
		}

		.flex-container {
			margin: 0 -2rem;

			@media (max-width: $medium) {
				margin: 0;
			}
		}

		.prices-wrapper {
			width: 50%;
			padding: 0 2rem;

			@media (max-width: $medium) {
				width: 100%;
				padding: 0;
			}
		}

		.price-wrapper {
			margin-bottom: 6rem;

			@media (max-width: $medium) {
				margin-bottom: 4rem;
			}
		}

		table {
			table-layout: auto;
			font-size: 1.7rem;
			border: none;

			th, td {
				vertical-align: middle;
			}

			thead {
				border-top: .1rem solid $light-grey;
				border-bottom: .1rem solid $light-grey;
				color: $purple;

				th {
					padding: 1rem 1.5rem;
					border: none;
				}
			}

			tbody {
				tr {
					border-top: .1rem solid $light-grey;

					&:nth-of-type(odd) {
						background-color: rgba($light-grey, .2);
					}

					td:last-of-type {
						@media (max-width: $medium) {
							text-align: right;
						}
					}
				}

				td {
					padding: 1rem 1.5rem;
					border: none;

					@media (max-width: $tiny) {
						line-height: 1.8rem;
					}
				}
			}
		}

		.disclaimer {
			margin: -3rem 0 0;
			text-align: center;

			@media (max-width: $medium) {
				margin: -2rem 2rem 0;
			}

			@media (max-width: $small) {
				margin: -2rem 1.5rem 0;
			}
		}
	}

	.home-news {
		overflow: hidden;

		.container {
			padding-bottom: 14rem;

			@media (max-width: $extra-large) {
				padding-bottom: 7rem;
			}

			@media (max-width: $medium) {
				padding-bottom: 3rem;
			}

			@media (max-width: $tiny) {
				padding-bottom: 1rem;
			}
		}

		.flex-container {
			margin: 0 -3rem;

			@media (max-width: $medium) {
				margin: 0;
			}
		}

		.new-wrapper {
			position: relative;
			width: 50%;
			padding: 0 3rem;

			@media (max-width: $medium) {
				width: 100%;
				padding: 0 0 3rem;
			}

			&:hover {
				.news-fake-link {
					text-decoration: underline;

					svg {
						fill: $purple;
					}
				}

				h3, .news-fake-link {
					color: $purple;
				}
			}
		}

		.news-link {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		}

		.news-pic {
			width: 100%;
			height: auto;
		}

		h3 {
			position: relative;
			top: -3rem;
			max-width: 80%;
			margin: 0 auto;
			text-align: center;
			padding: 2rem;
			font-size: 2.2rem;
			font-weight: 400;
			text-transform: uppercase;
			color: $purple-fade;
			background-color: $beige;
			transition: color .3s ease-in-out;
		}

		.preview-wrapper {
			margin-top: -1rem;
			font-size: 1.7rem;
		}

		.news-fake-link {
			display: block;
			margin-top: 1.5rem;
			font-size: 1.7rem;
			font-weight: 400;
			text-align: right;
			color: $purple-fade;
			transition: color .3s ease-in-out;

			svg {
				width: 1.3rem;
				height: 1.3rem;
				vertical-align: middle;
				fill: $purple-fade;
				transition: fill .3s ease-in-out;
			}
		}
	}

	.home-contact {
		@extend %bg-cover;

		.container {
			@media (max-width: $medium) {
				max-width: none;
				padding: 0;
			}
		}

		.content-wrapper {
			max-width: 76rem;
			padding: 3rem 2rem 5rem;
			background-color: rgba($beige, .85);

			@media (max-width: $tiny) {
				padding: 3rem 1.5rem 5rem;
			}
		}

		h2.title-home {
			margin-bottom: 3rem;
		}

		svg {
			fill: $grey;
		}

		.flex-container {
			font-size: 1.7rem;
		}

		.adress-wrapper, .schedule-wrapper {
			margin-bottom: 2rem;

			svg {
				width: 1.3rem;
				height: 1.3rem;
				margin-right: .3rem;
			}

			p {
				margin: 0;
			}
		}

		.adress-wrapper {
			margin-right: 5rem;

			p:first-of-type {
				font-weight: 400;
			}
		}

		.appointment {
			margin-bottom: 2rem;

			svg {
				width: 2rem;
				height: 2rem;
				margin-right: .3rem;
				vertical-align: text-top;
			}
		}

		.form-message {
			margin-bottom: 2rem;
			padding: 1rem 1.5rem;
			color: $white;

			&.form-notice {
				background-color: $success;
			}

			&.form-erreur {
				background-color: $error;
			}
		}

		.error {
			display: block;
			margin-top: 1rem;
			font-weight: 400;
			color: $error;
		}

		.form-item-submit {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 2rem;

			@media (max-width: $medium) {
				flex-direction: column;
				justify-content: center;
				align-items: flex-end;
				padding: 1rem 0;
			}

			@media (max-width: $small) {
				padding: 0 0 1rem;

				#rc-imageselect, .g-recaptcha {
					transform: scale(0.8) !important;
					transform-origin: 100% 100% !important;
				}
			}
		}

		.form-submit {
			padding: 2rem 4rem;
			cursor: pointer;
			border: none;
			outline: none;
			border-radius: 0;
			letter-spacing: .3rem;
			color: $white;
			background-color: $purple-fade;
			transition: background-color .3s ease-in-out;

			&:hover {
				background-color: $purple;
			}

			@media (max-width: $medium) {
				margin-top: 1.5rem;
			}
		}

		.map {
			width: 100%;
			height: 35rem;
			margin-top: 2rem;

			@media (max-width: $tiny) {
				height: 25rem;
			}
		}
	}