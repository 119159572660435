
	// Styles de formulaire
		
		// Reset
			
			input[type=number] {
				-moz-appearance: textfield;
			}

			input[type=number]::-webkit-inner-spin-button, 
			input[type=number]::-webkit-outer-spin-button { 
				-webkit-appearance: none; 
				margin: 0; 
			}

			input:-webkit-autofill { // Background Yellow on Chrome
			    -webkit-box-shadow: 0 0 0 3rem white inset;
			}

			::-ms-clear {
		        display: none;
		  	}

		  	::-ms-reveal {
		        display: none;
		  	}

		  	input[type=text], textarea {   
		    	-webkit-appearance: none;
		   	 	-moz-appearance: none;
		    	appearance: none;
		    }

		// Placeholder

			::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				font-weight: 300;
				font-size: 1.8rem;
				letter-spacing: .1rem;
				color: $font-grey;

				@media (max-width: $tiny) {
					font-size: 1.6rem;
				}
			}

			::-moz-placeholder { /* Firefox 19+ */
				font-weight: 300;
				font-size: 1.8rem;
				letter-spacing: .1rem;
				color: $font-grey;

				@media (max-width: $tiny) {
					font-size: 1.6rem;
				}
			}

			:-ms-input-placeholder { /* IE 10+ */
				font-weight: 300;
				font-size: 1.8rem;
				letter-spacing: .1rem;
				color: $font-grey;

				@media (max-width: $tiny) {
					font-size: 1.6rem;
				}
			}

			:-moz-placeholder { /* Firefox 18- */
				font-weight: 300;
				font-size: 1.8rem;
				letter-spacing: .1rem;
				color: $font-grey;

				@media (max-width: $tiny) {
					font-size: 1.6rem;
				}
			}
		
		// Styles spécifiques

			input, textarea {
				width: 100%;
				margin: 1.5rem 0 0;
				padding: 1.5rem;
				outline: none;
				border: none;
				box-shadow: 0 .1rem .3rem .2rem rgba($black, .1);
			}


