
	// Fonts (Polices de caractère)

		// Futura

			// Light

			@font-face {
			    font-family: 'Futura';
			    src: url('../fonts/futura-light/futura-light.woff2') format('woff2'),
			         url('../fonts/futura-light/futura-light.woff') format('woff'),
			         url('../fonts/futura-light/futura-light.ttf') format('truetype'),
					 url('../font/futura-light/futura-light.svg#FuturaLight') format('svg');
			    font-weight: 300;
			    font-style: normal;
			}

			// Medium

			@font-face {
			    font-family: 'Futura';
			    src: url('../fonts/futura-medium/futura-medium.woff2') format('woff2'),
			         url('../fonts/futura-medium/futura-medium.woff') format('woff'),
			         url('../fonts/futura-medium/futura-medium.ttf') format('truetype'),
					 url('../font/futura-medium/futura-medium.svg#FuturaMedium') format('svg');
			    font-weight: 400;
			    font-style: normal;
			}

			// Medium italic

			@font-face {
			    font-family: 'Futura';
			    src: url('../fonts/futura-medium-italic/futura-medium-italic.woff2') format('woff2'),
			         url('../fonts/futura-medium-italic/futura-medium-italic.woff') format('woff'),
			         url('../fonts/futura-medium-italic/futura-medium-italic.ttf') format('truetype'),
					 url('../font/futura-medium-italic/futura-medium-italic.svg#FuturaMediumItalic') format('svg');
			    font-weight: 400;
			    font-style: italic;
			}	

			// Bold

			@font-face {
			    font-family: 'Futura';
			    src: url('../fonts/futura-bold/futura-bold.woff2') format('woff2'),
			         url('../fonts/futura-bold/futura-bold.woff') format('woff'),
			         url('../fonts/futura-bold/futura-bold.ttf') format('truetype'),
					 url('../font/futura-bold/futura-bold.svg#FuturaBold') format('svg');
			    font-weight: 700;
			    font-style: normal;
			}