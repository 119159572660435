
	// Pied de page

	footer {
		position: relative;
		padding: 0 0 5rem;

		@media (max-width: $tiny) {
			padding-bottom: 3rem;
		}

		.wave-wrapper {
			position: absolute;
			top: -7rem;
		    width: 100%;
		    height: 15rem;
		    overflow: hidden;

		    @media (max-width: $tiny) {
		    	top: -4rem;
		    	height: 12rem;
		    }

			.svg-wave {
	        	position: static;
	        }
		}

		.container {
			display: flex;
			align-items: center;

			@media (max-width: $tiny) {
				flex-direction: column;
				justify-content: center;
			}

			p {
				margin: 0;
				font-size: 1.5rem;

				@media (max-width: $tiny) {
					text-align: center;
				}

				&:first-of-type {
					margin-bottom: .5rem;
				}

				a {
					margin-right: 1rem;
					text-decoration: underline;

					span {
						color: $purple-fade;
					}
				}
			}
		}

		.fb-link {
			margin-right: 3rem;

			@media (max-width: $tiny) {
				margin: 0 0 1rem;
			}

			svg {
				width: 4rem;
				height: 4rem;
				fill: $purple-fade;
				transition: fill .3s ease-in-out;
			}

			&:hover svg {
				fill: $purple;
			}
		}
	}

	.btn-top {
		justify-content: center;
		align-items: center;
		position: fixed;
		right: 5%;
		bottom: 0;
		padding: 1rem;
		color: $purple-fade;
		background-color: $white;
		transition: color .3s ease-in-out;

		@media (max-width: $tiny) {
			right: 0;
		}

		svg {
			width: 2.5rem;
			height: 2.5rem;
			fill: $purple-fade;
			transition: fill .3s ease-in-out;
		}

		span {
			text-align: center;

			@media (max-width: $tiny) {
				display: none;
			}
		}

		&:hover {
			color: $purple;

			svg {
				fill: $purple;
			}
		}
	}