
	// Navigation

		.header {
			display: flex;
			justify-content: center;
			align-items: center;
			position: fixed;
			width: 100%;
			height: 11rem;
			padding: 1rem 1rem 0;
			background-color: rgba($white, .95);
			transition: all .3s ease-in-out;
			z-index: 2;

			&.is-scroll {
				height: 9rem;
				background-color: $white;

				.logo-nuances svg {
					width: 17rem;
					height: 2.9rem;
				}

				.links-wrapper {
					@media (max-width: $large) {
					    height: calc(100vh - 9rem);
					    background-color: white;
					}
				}

				.nav-link {
					font-size: 1.5rem;
				}

				.hamburger .hamburger-label {
					font-size: 1.7rem
				}
			}

			&.is-mobile-nav-open .links-wrapper {
				pointer-events: auto;
				opacity: 1;
			}

			.navigation {
				width: 100%;
				text-align: center;

				.container {
					position: static;
				}
			}

			.logo-nuances {
				display: block;
				margin-bottom: .5rem;

				svg {
					width: 20rem;
					height: 3.4rem;
					fill: $grey;
					transition: all .3s ease-in-out;
				}
			}

			.links-wrapper {
				justify-content: center;

				@media (max-width: $large) {
					flex-direction: column;
					position: absolute;
				    top: 100%;
				    left: 0;
				    right: 0;
				    height: calc(100vh - 11rem);
				    pointer-events: none;
				    background-color: rgba($white, .95);
				    opacity: 0;
				    transition: opacity .3s ease-in-out;
				}
			}

			.nav-link {
				margin: 0 2.5rem;
				padding: 1rem;
				cursor: pointer;
				text-decoration: none;
				letter-spacing: .1rem;
				font-size: 1.8rem;
				font-weight: 400;
				text-transform: uppercase;
				color: $font-grey;
				transition: all .3s ease-in-out;

				&:hover {
					color: $purple;
				}

				@media (max-width: $large) {
					margin: 0;
					padding: 1.5rem;
				}
			}

			.hamburger {
				align-items: center;
				outline: none;
				cursor: pointer;

				@media (min-width: $large + 1) {
					display: none;
				}

				&:hover {
					.hamburger-label {
						color: $purple;
					}

					.hamburger-box {
						.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
							color: $purple;
						}
					}
				}
				
				.hamburger-label {
					margin-right: .5rem;
				    text-transform: uppercase;
				    letter-spacing: .2rem;
				    font-weight: 300;
				    transition: all .3s ease-in-out;
				}
			}
		}